
import { mapGetters } from "vuex";

export default {
	data() {
		return {

		}

	},
	computed: {
		...mapGetters({
			isMoscow: 'isMoscow',
			// showComm: 'showComm',
		}),
		siteList() {
			return !this.$device.isMobile ? [
				// {
				// 	name: 'Новые авто',
				// 	link: '/new',
				// 	show: true
				//
				// },
				{
					name: 'Авто с пробегом',
					link: '/cars',
					show: true

				},
				{
					name: 'Автокредит',
					link: '/credit',
					show: true
				},
				{
					name: 'Trade-In',
					link: '/exchange',
					show: true
				},
				{
					name: 'Выкуп',
					link: '/buyout',
					show: true
				},
				{
					name: 'РАССРОЧКА',
					link: '/installments',
					show: true
				},
				{
					name: 'Контакты',
					link: '/contacts',
					show: true
				},
				{
					name: 'Об автосалоне',
					link: '/about',
					show: true
				},
				{
					name: 'Банки-партнеры',
					link: '/banks',
					show: true
				},
			] :
				[
					// {
					// 	name: 'Новые авто',
					// 	link: '/new',
					// 	show: true
					// },
					{
						name: 'Авто с пробегом',
						link: '/cars',
						show: true

					},
					{
						name: 'Автокредит',
						link: '/credit',
						show: true
					},
					{
						name: 'Trade-In',
						link: '/exchange',
						show: true
					},
					{
						name: 'Выкуп',
						link: '/buyout',
						show: true
					},
					{
						name: 'РАССРОЧКА',
						link: '/installments',
						show: true
					},
					{
						name: 'Контакты',
						link: '/contacts',
						show: true
					},
					{
						name: 'Об автосалоне',
						link: '/about',
						show: true
					},
					{
						name: 'Банки-партнеры',
						link: '/banks',
						show: true
					},
					// {
					// 	name: 'Избранное',
					// 	link: '/favorites',
					// 	show: true
					// },
				]
		},
		siteList_sub() {
			return [
				// {
				// 	name: 'Отзывы',
				// 	link: '/reviews',
				// 	show: true
				// },
				{
					name: 'Контакты',
					link: '/contacts',
					show: true
				},
				{
					name: 'Об автоцентре',
					link: '/about',
					show: true
				}

			]
		}
	}
}
